import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Page from "../templates/page";
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql";

const Home = () => (
  <StaticQuery
    query={query}
    render={withPreview(
      (data) => (
        <Page data={data} />
      ),
      query
    )}
  />
);

export default Home;

const query = graphql`
  query homePageQuery {
    prismic {
      page(lang: "en-au", uid: "home") {
        seo {
          seo_description
          seo_image
          seo_title
          keywords
          business_information {
            ... on PRISMIC_Business_information {
              business_name
              logo
              address
              city
              country
              description
              state_region
              telephone
              url
              zip
            }
          }
        }
        body {
          ... on PRISMIC_PageBodyHero_image {
            type
            label
            primary {
              background_color
              call_to_action_label
              call_to_action_link {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              description
              display_cta
              hero_image
              title
            }
          }
          ... on PRISMIC_PageBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_PageBodyVideo_panel {
            type
            label
            primary {
              theme
              video_link {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              video_title
              video_title_emphasis
            }
          }
          ... on PRISMIC_PageBodyCard_panel {
            type
            label
            primary {
              link {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              bullet_points
              card_panel_image
              description
              image_location
              linktext
              subtitle
              title
            }
          }
          ... on PRISMIC_PageBodyCall_to_action {
            type
            label
            primary {
              fixed_color
              image
              link {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
              link_text
              subtitle
              title1
              cta_type
            }
          }
          ... on PRISMIC_PageBodyFrequent_asked_question {
            type
            label
            fields {
              answer
              question
            }
          }
          ... on PRISMIC_PageBodyPull_quote {
            type
            label
            primary {
              author
              background_color
              quote
            }
          }
          ... on PRISMIC_PageBodyImage_panel {
            type
            label
            primary {
              image
              subtitle
            }
          }
          ... on PRISMIC_PageBodyExpression_of_interest {
            type
            label
            primary {
              show_eoi_form
            }
          }
          ... on PRISMIC_PageBodyCard_form {
            label
            fields {
              field_name
              field_label
              field_placeholder
              field_type
              field_value
              required
            }
            primary {
              card_heading
              card_sub_heading
              form_heading
              form_sub_heading
              form_post_action_id
              content
              bullet_points
            }
            type
          }
          ... on PRISMIC_PageBodyRelated_pages {
            type
            label
            primary {
              title
            }
            fields {
              related_page {
                ... on PRISMIC_Page {
                  _linkType
                  seo {
                    seo_description
                    seo_image
                    seo_title
                  }
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC_Case_study {
                  title
                  seo {
                    seo_description
                    seo_image
                    seo_title
                  }
                  _meta {
                    uid
                  }
                }
              }
            }
          }
          ... on PRISMIC_PageBodyLanding_page_ {
            type
            label
            primary {
              image
              title
              description
              display_cta
              cta_secondary_label
              cta_primary_label
              cta_secondary {
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
              }
              cta_primary {
                ... on PRISMIC_Page {
                  _linkType
                  _meta {
                    uid
                  }
                }
                ... on PRISMIC__ExternalLink {
                  target
                  _linkType
                  url
                }
              }
            }
          }
          ... on PRISMIC_PageBodyOur_team {
            type
            label
            primary {
              title
            }
            fields {
              avatar
              description
              full_name
              job_title
              url_destination
              url_display_text
              url_icon
            }
          }
          ... on PRISMIC_PageBodyClient_logos {
            type
            label
            primary {
              title
            }
            fields {
              logo
              client_website
            }
          }
        }
        hide_navigation_menu
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
